<template>
  <div>
<!--    <DashboardUtama/>-->
  </div>
</template>

<script>
// import DashboardUtama from "../components/dashboard/DashboardUtama";
export default {
  name: "DashboardUtama",
  components:{
    // DashboardUtama
  }
}
</script>

<style scoped>

</style>